import useInterval from "@use-it/interval";
import { useEffect, useState } from "react";

const VALID_CHARS = "abcdefghijklmnopqrstuvwxyz0123456789$+-*/=%\"'#_(),.;:?!\\|[]<>{}^~`";
const STREAM_MUTATION_ODDS = 0.02;

const min_stream_size = 15;
const max_stream_size = 50;

const getRandInRange = (min, max) =>  Math.floor(Math.random() * (max - min) + min);
const getRandChar = () => VALID_CHARS.charAt(Math.floor(Math.random()*VALID_CHARS.length))

const getRandStream = () => 
new Array(getRandInRange(min_stream_size, max_stream_size))
.fill()
.map(_ => getRandChar());

const getMutatedStream = stream =>{
    const newStream = [];
    for(let i=0 ;i< stream.length;++i){
        if(Math.random() < STREAM_MUTATION_ODDS){
            newStream.push(getRandChar());
        }else{
            newStream.push(stream[i]);
        }
    }
    newStream.push(getRandChar());
    return newStream;
}

const RainStream = () => {
    const [stream, setStream] = useState(getRandStream());
    const [topPadding, setTopPadding] = useState(stream.length * -50);
  
    // Your existing useInterval hook
    useInterval(() => {
      if (topPadding > window.innerHeight) {
        setTopPadding(stream.length * -50);
      } else {
        setTopPadding(topPadding + 44);
        setStream(getMutatedStream);
      }
    }, 100);

    return ( 
        <div  className="stream"
            style={{
                marginTop: topPadding,
                fontFamily: 'matrixFontJapanese',
                color: 'var(--primaryColor)',
                writingMode: 'vertical-rl',
                textOrientation: 'upright',
                whiteSpace:'nowrap',
                userSelect: 'none',
                textShadow: "0px 0px 8px rgba(32,194,14,0.8)",
                fontSize: 40,
            }}
            >
            {stream.map((char, index) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a key={index}
                style={{
                    color: index === stream.length - 1 ? "#fff" : undefined,
                    opacity: index < 6 ? 0.1 + index * 0.15 : 1,
                    textShadow: 
                    index === stream.length - 1 ?
                    '0px 0px 20px rgba(255, 255, 255, 1)' 
                    : undefined,
                    marginTop: -12,
                }}
                >{char}</a>
            ))}
        </div>
    );
}
 
export default RainStream;