import { useEffect, useState } from 'react';
import '../stylesheets/cv.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

const CV = ({contentOpacity}) => {
    const downloadIcon = <FontAwesomeIcon icon={faFileArrowDown} />;
    const backIcon = <FontAwesomeIcon icon={faArrowLeft} />;
    let selectedContentFromMenu = sessionStorage.getItem("selectedCVLinkFromHome");

    if(selectedContentFromMenu == null || selectedContentFromMenu === "undefined"){
        selectedContentFromMenu = "CV";
        sessionStorage.setItem("selectedCVLinkFromHome",selectedContentFromMenu);
    } 

    const [selectedCVLink, setSelectedCVLink] = useState(selectedContentFromMenu);
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [selectedEducation, setSelectedEducation] = useState(null);
    
    useEffect(() => {
        setSelectedCVLink(selectedContentFromMenu);
    },[selectedContentFromMenu])

    const navigateLinkCVSelection = (selectedLink) => {
        sessionStorage.setItem("selectedCVLinkFromHome",selectedLink)
        setSelectedCVLink(selectedLink);
    }

    const updateSelectedEducation = (school) => {
        setSelectedEducation(school);
    }
    const updateSelectedSkill = (skill) => {
        setSelectedSkill(skill);
    }

    return ( 
        <div className="content">
         
            <div className="cvContent" style={{
                    opacity:contentOpacity
                }}>
                <div className='navigator'>
                    <div className={selectedCVLink === "CV" ? "selectedContentLink navigateLink" : "navigateLink"} onClick={() => navigateLinkCVSelection("CV")}>CV</div>
                    <div className={selectedCVLink === "Skills" ? "selectedContentLink navigateLink" : "navigateLink"} onClick={() => navigateLinkCVSelection("Skills")}>Skills</div>
                    <div className={selectedCVLink === "Education" ? "selectedContentLink navigateLink" : "navigateLink"} onClick={() => navigateLinkCVSelection("Education")}>Education</div>
                    <div className={selectedCVLink === "Experience" ? "selectedContentLink navigateLink" : "navigateLink"} onClick={() => navigateLinkCVSelection("Experience")}>Experience</div>
                </div>
                <div className='aboutDivs'> 
                    <div className='contentInner'>
                    <h2 className='cvSelectedTitle'>{selectedCVLink}</h2>
                        {selectedCVLink === "CV" &&  
                            <div className='aboutDiv cvDiv'>
                                <div className='resumeContent'>
                                    <img loading='lazy' src={require("../assets/Resume.png")} alt='resumé'/>
                                    <div>
                                        <h2>Mehmet Kaan Taspunar</h2>
                                        <h4>Fullstack Developer</h4>
                                        <p>
                                            As a recent graduate from Malmö University with a Bachelor's degree in Media Production and Process Design, 
                                            I am eager to kickstart my career in fullstack development. I am a highly motivated and detail-oriented developer 
                                            with a passion for creating efficient, innovative, and user-friendly software solutions.
                                        </p>
                                        <a  href={require("../assets/Resume_Mehmet_Kaan_Taspunar.pdf")} download="Resume_Mehmet_Kaan_Taspunar.pdf" className="downloadButton">
                                            {downloadIcon} Resumé
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }
                        {selectedCVLink === "Skills" &&   
                            <div className='aboutDiv skillsDiv'>
                                <div className='skillsContent'>
                                    <div className='skillsBoxes'>
                                        <div className={selectedSkill === "frontend" ? "selectedSkill skillBox" : "skillBox"} onClick={() => updateSelectedSkill("frontend")}>
                                            <img className='skillLogo' src={require("../assets/images/skills/frontend.png")} alt="frontend" />
                                            <h2 className='skillBoxTitle'>Front-end Development</h2>
                                        </div>
                                        <div className={selectedSkill === "backend" ? "selectedSkill skillBox" : "skillBox"} onClick={() => updateSelectedSkill("backend")}>
                                            <img className='skillLogo' src={require("../assets/images/skills/backend.png")} alt="backend" />
                                            <h2 className='skillBoxTitle'>Back-end Development</h2>
                                        </div>
                                        <div className={selectedSkill === "cloud" ? "selectedSkill skillBox" : "skillBox"} onClick={() => updateSelectedSkill("cloud")}>
                                            <img className='skillLogo' src={require("../assets/images/skills/cloudservices.png")} alt="cloudservices" />
                                            <h2 className='skillBoxTitle'>Cloud Services</h2>
                                        </div>
                                        <div className={selectedSkill === "worthtomention" ? "selectedSkill skillBox" : "skillBox"} onClick={() => updateSelectedSkill("worthtomention")}>
                                            <img className='skillLogo' src={require("../assets/images/skills/worthtomention.png")} alt="worthtomention" />
                                            <h2 className='skillBoxTitle'>Worth to Mention</h2>
                                        </div>
                                    </div>
                                    <div className='selectedSkillContent'>
                                        {selectedSkill === "frontend" ? (
                                            // Content for frontend skills
                                            <>
                                                <p><span>Front-end Developing Skills</span></p>
                                                <li>JavaScript - Visual Studio Code</li>
                                                <li>ReactJS - React Native</li>
                                                <li>UI/UX Design - Adobe Figma, AdobeXD</li>
                                                <li>Objectoriented Java Development - IntelliJ</li>
                                                <li>Basic C# - Visual Studio</li>
                                            </>
                                        ) : selectedSkill === "backend" ? (
                                            // Content for backend skills
                                            <>
                                                <p><span>Back-end Developing Skills</span></p>
                                                <li>PHP</li>
                                                <li>Node.js</li>
                                                <li>Firebase</li>
                                                <li>Insomnia - Testing API:s</li>
                                            </>
                                        ) : selectedSkill === "cloud" ? (
                                            // Content for cloud skills
                                            <>
                                                <p><span>Cloud Services Skills</span></p>
                                                <li>Google Cloud Plattfrom - GCP</li>
                                            </>
                                        ) : selectedSkill === "worthtomention" ? (
                                            // Content for Umeå University
                                            <>
                                                <p><span>Worth to Mention Skills</span></p>
                                                <li>3D Modeling - Blender </li>
                                                <li>Basic Android Game Development - Android Studio</li>
                                                <li>Music and Sound Processing - Max/MSP</li>
                                                <li>Git, Github</li>
                                            </>
                                        ) : (
                                            // Default content when no specific education is selected
                                            <>
                                                <p>Select a skill!</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className='skillsContentMobile'>
                                    {selectedSkill == null && (
                                        <div className='skillsBoxes'>
                                        <div className={selectedSkill === "frontend" ? "selectedSkill skillBox" : "skillBox"} onClick={() => updateSelectedSkill("frontend")}>
                                            <img className='skillLogo' src={require("../assets/images/skills/frontend.png")} alt="frontend" />
                                            <h2 className='skillBoxTitle'>Front-end Development</h2>
                                        </div>
                                        <div className={selectedSkill === "backend" ? "selectedSkill skillBox" : "skillBox"} onClick={() => updateSelectedSkill("backend")}>
                                            <img className='skillLogo' src={require("../assets/images/skills/backend.png")} alt="backend" />
                                            <h2 className='skillBoxTitle'>Back-end Development</h2>
                                        </div>
                                        <div className={selectedSkill === "cloud" ? "selectedSkill skillBox" : "skillBox"} onClick={() => updateSelectedSkill("cloud")}>
                                            <img className='skillLogo' src={require("../assets/images/skills/cloudservices.png")} alt="cloudservices" />
                                            <h2 className='skillBoxTitle'>Cloud Services</h2>
                                        </div>
                                        <div className={selectedSkill === "worthtomention" ? "selectedSkill skillBox" : "skillBox"} onClick={() => updateSelectedSkill("worthtomention")}>
                                            <img className='skillLogo' src={require("../assets/images/skills/worthtomention.png")} alt="worthtomention" />
                                            <h2 className='skillBoxTitle'>Worth to Mention</h2>
                                        </div>
                                    </div>
                                    )}
                                   {selectedSkill && (
                                    <div className='selectedSkillContent'>
                                        <button className="backBtn" onClick={()=> {setSelectedSkill(null)}}>{backIcon}</button>
                                        {selectedSkill === "frontend" ? (
                                            // Content for frontend skills
                                            <>
                                                <img className='skillLogoBig' src={require("../assets/images/skills/frontend.png")} alt="frontend" />
                                                <p><span>Front-end Developing Skills</span></p>
                                                <ul>
                                                    <li>JavaScript - Visual Studio Code</li>
                                                    <li>ReactJS - React Native</li>
                                                    <li>UI/UX Design - Adobe Figma, AdobeXD</li>
                                                    <li>Objectoriented Java Development - IntelliJ</li>
                                                    <li>Basic C# - Visual Studio</li>
                                                </ul>
                                            </>
                                        ) : selectedSkill === "backend" ? (
                                            // Content for backend skills
                                            <>
                                                <img className='skillLogoBig' src={require("../assets/images/skills/backend.png")} alt="backend" />
                                                <p><span>Back-end Developing Skills</span></p>
                                                <ul>
                                                    <li>PHP</li>
                                                    <li>Node.js</li>
                                                    <li>Firebase</li>
                                                    <li>Insomnia - Testing API:s</li>
                                                </ul>
                                            </>
                                        ) : selectedSkill === "cloud" ? (
                                            // Content for cloud skills
                                            <>
                                                <img className='skillLogoBig' src={require("../assets/images/skills/cloudservices.png")} alt="cloudservices" />
                                                <p><span>Cloud Services Skills</span></p>
                                                <ul>
                                                    <li>Google Cloud Plattfrom - GCP</li>
                                                </ul>
                                            </>
                                        ) : selectedSkill === "worthtomention" ? (
                                            // Content for Umeå University
                                            <>
                                                <img className='skillLogoBig' src={require("../assets/images/skills/worthtomention.png")} alt="worthtomention" />
                                                <p><span>Worth to Mention Skills</span></p>
                                                <ul>
                                                    <li>3D Modeling - Blender </li>
                                                    <li>Basic Android Game Development - Android Studio</li>
                                                    <li>Music and Sound Processing - Max/MSP</li>
                                                    <li>Git, Github</li>
                                                </ul>
                                            </>
                                        ) : (
                                            // Default content when no specific education is selected
                                            <>
                                                <p>Select a skill!</p>
                                            </>
                                        )}
                                    </div>
                                    )}
                                </div>
                            </div>
                        }
                        {selectedCVLink === "Education" &&    
                            <div className='aboutDiv educationDiv'>
                                <div className='educationContent'>
                                    <div className='educationBoxes'>
                                        <div className={selectedEducation === "malmo" ? "selectedSchool educationBox" : "educationBox"} onClick={() => updateSelectedEducation("malmo")}>
                                            <img className='educationImg' src={require("../assets/images/education/mau.png")} alt="gävle" />
                                            <div>
                                                <h2 className='educationBoxTitle'>Malmö University</h2>
                                                <h3>Bachelor's degree in Media Production and Process Design, 2023</h3>
                                            </div>
                                        </div>
                                        <div className={selectedEducation === "gavle" ? "selectedSchool educationBox" : "educationBox"} onClick={() => updateSelectedEducation("gavle")}>
                                            <img className='educationImg' src={require("../assets/images/education/hgevle.png")} alt="gävle" />
                                            <div>
                                                <h2 className='educationBoxTitle'>University of Gävle</h2>
                                                <h3>3D Modeling and Animation in an Open Source Environment</h3>
                                            </div>
                                        </div>
                                        <div className={selectedEducation === "kristianstad" ? "selectedSchool educationBox" : "educationBox"} onClick={() => updateSelectedEducation("kristianstad")}>
                                            <img className='educationImg' src={require("../assets/images/education/hkr.png")} alt="hkr" />
                                            <div>
                                                <h2 className='educationBoxTitle'>Kristianstad University</h2>
                                                <h3>Game development for Android</h3>
                                            </div>
                                        </div>
                                        <div className={selectedEducation === "umeo" ? "selectedSchool educationBox" : "educationBox"} onClick={() => updateSelectedEducation("umeo")}>
                                            <img className='educationImg' src={require("../assets/images/education/umeo.png")} alt="umeo" />
                                            <div>
                                                <h2 className='educationBoxTitle'>Umeå University</h2>
                                                <h3>Music and Sound Processing in MAX/MSP</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='selectedEducationContent'>
                                        <p><span>About</span></p>
                                        {selectedEducation === "malmo" ? (
                                            // Content for Malmö University
                                            <>
                                                <p>
                                                    I earned my Bachelor's degree in Media Production and Process Design from Malmö University, delving into a program tailored for those passionate about developing and integrating diverse media. Positioned as a creative problem solver in media production, the curriculum encompassed various publication methods, ranging from traditional print and web to contemporary mobile information publishing.
                                                    <br/>
                                                    <br/>
                                                    During my time at Malmö University, I immersed myself in the captivating world of Media Production and Process Design, tailored for individuals like me who enjoy solving creative challenges within the realm of media production. This educational journey provided me with profound knowledge and skills, empowering me to independently navigate the dynamic landscape of media technology. I not only gained insights into the scientific foundations of media technology but also remained abreast of ongoing research and development within the field. My education instilled in me a comprehensive perspective rooted in process knowledge for both print and digital publishing.
                                                    <br/>
                                                    <br/>
                                                    My studies covered a broad spectrum of publications and methods, from traditional to cutting-edge mobile information publishing. As a process designer, I took charge of the entire technical production process, guiding ideas from conception to polished end products, including printed materials, dynamic media databases, user-friendly websites, and innovative solutions tailored to unique needs.
                                                    <br/>
                                                    <br/>
                                                    The completion of my education equipped me with the ability to actively seek, gather, evaluate, and critically interpret relevant information in the context of media production challenges. I honed my skills in planning and executing tasks within given parameters, adopting a holistic approach to independently and creatively identify, formulate, discuss, and manage issues within media technology. Networking became second nature, enabling effective collaboration in diverse group settings. Proficient in presenting information orally and in writing, I have applied scientific problem-solving methods, enhancing my ability to solve problems on a solid foundation.
                                                    <br/>
                                                    <br/>
                                                    Upon completing my education, I refined my ability to make informed judgments, considering relevant scientific, societal, and ethical aspects. Gaining insight into the possibilities and limitations of media technology, I understood its role in society and the responsibility individuals bear in its utilization. This encompasses social, economic, environmental, and occupational safety aspects. I developed the capability to identify my need for additional knowledge and continually evolve my skills, showcasing a commitment to ongoing learning and professional development.
                                                </p>
                                                <p><span>Skills: </span> Html, Css, JavaScript, PHP, UI/UX Design, Object-oriented Java, C#, Insomnia, Github</p>
                                            </>
                                        ) : selectedEducation === "gavle" ? (
                                            // Content for University of Gävle
                                            <>
                                                Completing the 3D Modeling and Animation in an Open Source Environment course at the University of Gävle has empowered me to:
                                                <ul>
                                                    <li>Craft polygon-based 3D models based on simple criteria and instructions, whether presented in text or imagery.</li>
                                                    <li>Apply materials, texture, and illuminate models according to straightforward criteria and instructions, enhancing their visual appeal.</li>
                                                    <li>Rig and animate 3D models using skeleton-based deformation and keyframing techniques, manipulating properties like position, scale, and rotation, all guided by clear criteria and instructions.</li>
                                                    <li>Independently create an animated sequence using fundamental techniques within a 3D application in an open-source environment.</li>
                                                </ul>
                                                Throughout this transformative course, I delved into:
                                                <ul>
                                                    <li>Experimentation with various methods for creating three-dimensional objects, characters, and environments, leveraging techniques like polygons and subdivision surfaces.</li>
                                                    <li>Exploring the realm of simple texturing and lighting to enhance the visual aesthetics of the models.</li>
                                                    <li>Mastering diverse animation methods, including keyframing, skeleton animation, and procedural animation, unlocking the potential for dynamic storytelling.</li>
                                                    <li>The art of character and environment modeling, providing a comprehensive understanding of bringing virtual worlds to life.</li>
                                                </ul>
                                                This journey has not only expanded my technical skills but has also allowed me to express creativity in the immersive world of 3D modeling and animation within an open-source environment.
                                                <p><span>Skills: </span>3D modeling and Animation, Blender</p>
                                            </>
                                        ) : selectedEducation === "kristianstad" ? (
                                            // Content for Kristianstad University
                                            <>
                                            <p>The Game Development for Android course at the University of Kristianstad was a pivotal journey for me, designed to empower students with foundational knowledge in crafting Android applications—from conceptualization to a refined end product. By the course's culmination, I successfully brought to life at least two Android games, all while immersing myself in the fascinating realm of research within the field.<br></br></p>
                                            <br/>
                                            Throughout the course, I delved into essential concepts shaping Android game development, navigating through:
                                            <ul>
                                                <li>Understanding the Android lifecycle and Activities</li>
                                                <li>Exploring Intents and Fragments</li>
                                                <li>Crafting user-friendly interfaces</li>
                                                <li>Mastering Android hardware intricacies</li>
                                                <li>Unleashing creativity through graphics and drawing of standard shapes and images</li>
                                                <li>Implementing background processing</li>
                                                <li>Breathing life into games through animation</li>
                                                <li>Harnessing gravity for immersive gaming experiences</li>
                                                <li>Diving into the intricacies of the Audio System</li>
                                                <li>Packaging and navigating the distribution process on Google Play</li>
                                                <li>Exploring various tools pivotal in Android application game development</li>
                                                <li>Gaining insights into game development from a research perspective</li>
                                                <li>Perfecting the art of report writing and documentation</li>
                                            </ul>
                                            <p>
                                            As I progressed through the course, the objectives became milestones for my personal and professional growth:<br></br>
                                            <br></br>
                                            I not only gained general knowledge but also a profound understanding of Android development.
                                            Post-course, I acquired the ability to develop software on the Android platform, independently create captivating games, present my work in a clear and cohesive manner, and critically assess and analyze the production of my applications, connecting my findings to scientific literature.
                                            <br></br>
                                            <br></br>
                                            Upon completion, I honed the skill to evaluate both my work and that of others, and contextualize Android application development within the realm of research.

                                            Embarking on this transformative course not only broadened my technical prowess but also fostered a comprehensive grasp of game development in the dynamic Android environment. 
                                            </p>
                                            <p><span>Skills: </span>Basic Android Spelutveckling, Android Studio</p>
                                            </>
                                        ) : selectedEducation === "umeo" ? (
                                            // Content for Umeå University
                                            <>
                                                <p>
                                                    Embarking on the Music and Sound Processing in MAX/MSP course at Umeå University was a transformative journey that seamlessly blended my passion for music, art, and technology. Tailored for students with backgrounds in music, art, interactive technology, design, or programming, the course unfolded a realm of possibilities within the realm of visual programming in Max/MSP.
                                                    </p>
                                                    <br/>
                                                    <br/>
                                                    Through hands-on exercises, I delved into the foundational aspects of visual programming, unraveling the intricacies of Max/MSP. The course's emphasis on unlocking new artistic potentials for composition, performance, and the creation of interactive installations offered an intriguing introduction to sound and music processing in Max/MSP.
                                                    <br/>
                                                    <br/>
                                                    <p>
                                                        <br/>
                                                        Max/MSP, renowned as one of the leading visual programming languages, became my creative playground. As I navigated its interface, I realized its significance for experimenting with music and multimedia. Widely embraced by composers, video artists, and creators worldwide, Max/MSP became the canvas for innovative sound compositions, applications, live performances, and interactive audio-video installations.
                                                    </p>
                                                    <p>
                                                        A unique aspect of this course was its entirely remote nature, conducted seamlessly through a dedicated learning platform. This virtual environment not only facilitated immersive learning but also underscored the adaptability of Max/MSP in diverse creative contexts.
                                                        <br/>
                                                    </p>
                                                    Upon completing the course, I achieved the following expected study outcomes:
                                                    <ul>
                                                        <li>Demonstrated fundamental knowledge in visual programming in Max/MSP.</li>
                                                        <li>Brought conceptual ideas to life by creating personalized patches in Max/MSP.</li>
                                                        <li>Developed the ability to comprehend, create, and modify my patches in Max/MSP.</li>
                                                    </ul>

                                                    This course not only enriched my understanding of Max/MSP but also provided me with practical skills to weave my artistic ideas into the fabric of this dynamic programming language.
                                                
                                                <p><span>Skills: </span>Music and Sound Processing - Max/MSP</p>
                                            </>
                                        ) : (
                                            // Default content when no specific education is selected
                                            <>
                                                <p>Select a University!</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className='educationContentMobile'>
                                    {selectedEducation == null && (
                                        <div className='educationBoxes'>
                                            <div className={selectedEducation === "malmo" ? "selectedSchool educationBox" : "educationBox"} onClick={() => updateSelectedEducation("malmo")}>
                                                <img className='educationImg' src={require("../assets/images/education/mau.png")} alt="mau" />
                                                <div>
                                                    <h2 className='educationBoxTitle'>Malmö University</h2>
                                                    <h3>Bachelor's degree in Media Production and Process Design, 2023</h3>
                                                </div>
                                            </div>
                                            <div className={selectedEducation === "gavle" ? "selectedSchool educationBox" : "educationBox"} onClick={() => updateSelectedEducation("gavle")}>
                                                <img className='educationImg' src={require("../assets/images/education/hgevle.png")} alt="gävle" />
                                                <div>
                                                    <h2 className='educationBoxTitle'>University of Gävle</h2>
                                                    <h3>3D Modeling and Animation in an Open Source Environment</h3>
                                                </div>
                                            </div>
                                            <div className={selectedEducation === "kristianstad" ? "selectedSchool educationBox" : "educationBox"} onClick={() => updateSelectedEducation("kristianstad")}>
                                                <img className='educationImg' src={require("../assets/images/education/hkr.png")} alt="hkr" />
                                                <div>
                                                    <h2 className='educationBoxTitle'>Kristianstad University</h2>
                                                    <h3>Game development for Android</h3>
                                                </div>
                                            </div>
                                            <div className={selectedEducation === "umeo" ? "selectedSchool educationBox" : "educationBox"} onClick={() => updateSelectedEducation("umeo")}>
                                                <img className='educationImg' src={require("../assets/images/education/umeo.png")} alt="umeo" />
                                                <div>
                                                    <h2 className='educationBoxTitle'>Umeå University</h2>
                                                    <h3>Music and Sound Processing in MAX/MSP</h3>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {selectedEducation && (
                                        <div className='selectedEducationContent'>
                                            <button className="backBtn" onClick={()=> {setSelectedEducation(null)}}>{backIcon}</button>
                                            <p><span>About</span></p>
                                            {selectedEducation === "malmo" ? (
                                                // Content for Malmö University
                                                <>
                                                    <p>
                                                        I earned my Bachelor's degree in Media Production and Process Design from Malmö University, delving into a program tailored for those passionate about developing and integrating diverse media. Positioned as a creative problem solver in media production, the curriculum encompassed various publication methods, ranging from traditional print and web to contemporary mobile information publishing.
                                                        <br/>
                                                        <br/>
                                                        During my time at Malmö University, I immersed myself in the captivating world of Media Production and Process Design, tailored for individuals like me who enjoy solving creative challenges within the realm of media production. This educational journey provided me with profound knowledge and skills, empowering me to independently navigate the dynamic landscape of media technology. I not only gained insights into the scientific foundations of media technology but also remained abreast of ongoing research and development within the field. My education instilled in me a comprehensive perspective rooted in process knowledge for both print and digital publishing.
                                                        <br/>
                                                        <br/>
                                                        My studies covered a broad spectrum of publications and methods, from traditional to cutting-edge mobile information publishing. As a process designer, I took charge of the entire technical production process, guiding ideas from conception to polished end products, including printed materials, dynamic media databases, user-friendly websites, and innovative solutions tailored to unique needs.
                                                        <br/>
                                                        <br/>
                                                        The completion of my education equipped me with the ability to actively seek, gather, evaluate, and critically interpret relevant information in the context of media production challenges. I honed my skills in planning and executing tasks within given parameters, adopting a holistic approach to independently and creatively identify, formulate, discuss, and manage issues within media technology. Networking became second nature, enabling effective collaboration in diverse group settings. Proficient in presenting information orally and in writing, I have applied scientific problem-solving methods, enhancing my ability to solve problems on a solid foundation.
                                                        <br/>
                                                        <br/>
                                                        Upon completing my education, I refined my ability to make informed judgments, considering relevant scientific, societal, and ethical aspects. Gaining insight into the possibilities and limitations of media technology, I understood its role in society and the responsibility individuals bear in its utilization. This encompasses social, economic, environmental, and occupational safety aspects. I developed the capability to identify my need for additional knowledge and continually evolve my skills, showcasing a commitment to ongoing learning and professional development.
                                                    </p>
                                                    <p><span>Skills: </span> Html, Css, JavaScript, PHP, UI/UX Design, Object-oriented Java, C#, Insomnia, Github</p>
                                                </>
                                            ) : selectedEducation === "gavle" ? (
                                                // Content for University of Gävle
                                                <>
                                                    Completing the 3D Modeling and Animation in an Open Source Environment course at the University of Gävle has empowered me to:
                                                    <ul>
                                                        <li>Craft polygon-based 3D models based on simple criteria and instructions, whether presented in text or imagery.</li>
                                                        <li>Apply materials, texture, and illuminate models according to straightforward criteria and instructions, enhancing their visual appeal.</li>
                                                        <li>Rig and animate 3D models using skeleton-based deformation and keyframing techniques, manipulating properties like position, scale, and rotation, all guided by clear criteria and instructions.</li>
                                                        <li>Independently create an animated sequence using fundamental techniques within a 3D application in an open-source environment.</li>
                                                    </ul>
                                                    Throughout this transformative course, I delved into:
                                                    <ul>
                                                        <li>Experimentation with various methods for creating three-dimensional objects, characters, and environments, leveraging techniques like polygons and subdivision surfaces.</li>
                                                        <li>Exploring the realm of simple texturing and lighting to enhance the visual aesthetics of the models.</li>
                                                        <li>Mastering diverse animation methods, including keyframing, skeleton animation, and procedural animation, unlocking the potential for dynamic storytelling.</li>
                                                        <li>The art of character and environment modeling, providing a comprehensive understanding of bringing virtual worlds to life.</li>
                                                    </ul>
                                                    This journey has not only expanded my technical skills but has also allowed me to express creativity in the immersive world of 3D modeling and animation within an open-source environment.
                                                    <p><span>Skills: </span>3D modeling and Animation, Blender</p>
                                                </>
                                            ) : selectedEducation === "kristianstad" ? (
                                                // Content for Kristianstad University
                                                <>
                                                <p>The Game Development for Android course at the University of Kristianstad was a pivotal journey for me, designed to empower students with foundational knowledge in crafting Android applications—from conceptualization to a refined end product. By the course's culmination, I successfully brought to life at least two Android games, all while immersing myself in the fascinating realm of research within the field.</p> 
                                                    Throughout the course, I delved into essential concepts shaping Android game development, navigating through:
                                                <ul>
                                                    <li>Understanding the Android lifecycle and Activities</li>
                                                    <li>Exploring Intents and Fragments</li>
                                                    <li>Crafting user-friendly interfaces</li>
                                                    <li>Mastering Android hardware intricacies</li>
                                                    <li>Unleashing creativity through graphics and drawing of standard shapes and images</li>
                                                    <li>Implementing background processing</li>
                                                    <li>Breathing life into games through animation</li>
                                                    <li>Harnessing gravity for immersive gaming experiences</li>
                                                    <li>Diving into the intricacies of the Audio System</li>
                                                    <li>Packaging and navigating the distribution process on Google Play</li>
                                                    <li>Exploring various tools pivotal in Android application game development</li>
                                                    <li>Gaining insights into game development from a research perspective</li>
                                                    <li>Perfecting the art of report writing and documentation</li>
                                                </ul>
                                                <p>
                                                As I progressed through the course, the objectives became milestones for my personal and professional growth:<br></br>
                                                <br></br>
                                                I not only gained general knowledge but also a profound understanding of Android development.
                                                Post-course, I acquired the ability to develop software on the Android platform, independently create captivating games, present my work in a clear and cohesive manner, and critically assess and analyze the production of my applications, connecting my findings to scientific literature.
                                                <br></br>
                                                <br></br>
                                                Upon completion, I honed the skill to evaluate both my work and that of others, and contextualize Android application development within the realm of research.

                                                Embarking on this transformative course not only broadened my technical prowess but also fostered a comprehensive grasp of game development in the dynamic Android environment. 
                                                </p>
                                                <p><span>Skills: </span>Basic Android Spelutveckling, Android Studio</p>
                                                </>
                                            ) : selectedEducation === "umeo" ? (
                                                // Content for Umeå University
                                                <>
                                                    <p>
                                                        Embarking on the Music and Sound Processing in MAX/MSP course at Umeå University was a transformative journey that seamlessly blended my passion for music, art, and technology. Tailored for students with backgrounds in music, art, interactive technology, design, or programming, the course unfolded a realm of possibilities within the realm of visual programming in Max/MSP.
                                                        </p>
                                                        Through hands-on exercises, I delved into the foundational aspects of visual programming, unraveling the intricacies of Max/MSP. The course's emphasis on unlocking new artistic potentials for composition, performance, and the creation of interactive installations offered an intriguing introduction to sound and music processing in Max/MSP.
                                                        <p>
                                                            <br/>
                                                            Max/MSP, renowned as one of the leading visual programming languages, became my creative playground. As I navigated its interface, I realized its significance for experimenting with music and multimedia. Widely embraced by composers, video artists, and creators worldwide, Max/MSP became the canvas for innovative sound compositions, applications, live performances, and interactive audio-video installations.
                                                        </p>
                                                        <p>
                                                            A unique aspect of this course was its entirely remote nature, conducted seamlessly through a dedicated learning platform. This virtual environment not only facilitated immersive learning but also underscored the adaptability of Max/MSP in diverse creative contexts.
                                                            <br/>
                                                        </p>
                                                        Upon completing the course, I achieved the following expected study outcomes:
                                                        <ul>
                                                            <li>Demonstrated fundamental knowledge in visual programming in Max/MSP.</li>
                                                            <li>Brought conceptual ideas to life by creating personalized patches in Max/MSP.</li>
                                                            <li>Developed the ability to comprehend, create, and modify my patches in Max/MSP.</li>
                                                        </ul>

                                                        This course not only enriched my understanding of Max/MSP but also provided me with practical skills to weave my artistic ideas into the fabric of this dynamic programming language.
                                                    
                                                    <p><span>Skills: </span>Music and Sound Processing - Max/MSP</p>
                                                </>
                                            ) : (
                                                // Default content when no specific education is selected
                                                <>
                                                    <p>Select a University!</p>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                        {selectedCVLink === "Experience" &&       
                            <div className='aboutDiv experienceDiv'>
                                <div className='experienceContent'>
                                    <div className='experienceBoxes'>
                                        <div className="experienceBox">
                                            <h2 className='experienceBoxTitle'>The Stepstone Group</h2>
                                            <h3>Intership as Software Engineer - 2023/2024</h3>
                                            <p>
                                                During my internship at The Stepstone Group, I explored technology, becoming proficient in Google Cloud Platform, Pub/Sub, and BigQuery, while also utilizing OpenAI APIs. This experience expanded my technical skills and deepened my passion for creating innovative solutions.
                                                A notable achievement during this period was the development of "Ask Gandalf," an AI application. This project allowed me to integrate my newfound knowledge into a practical solution, showcasing my ability to use cutting-edge technologies for meaningful applications.
                                                Throughout the internship, I tackled challenges, refined problem-solving skills, and collaborated with a dynamic team. The hands-on experience and exposure to real-world projects significantly contributed to my growth as a Software Engineer.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="sideImgCV">
                {/* <img
                    src={require("../assets/images/cvSide.jpg")}
                    alt="CV Side Image"
                    loading="lazy"
                /> */}
                    <div className='darkFilter'></div>
                </div>
            </div>
        </div>
     );
}
 
export default CV;