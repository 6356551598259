import '../stylesheets/home.css';
import { useEffect, useRef, useState } from "react";
import Navbar from './Navbar';
import CV from './CV';
import Projects from "./Projects";
import Contact from './Contact';

const Home = () => {
    const refs = {
        section1: useRef(),
        section2: useRef(),
        section3: useRef(),
        section4: useRef(),
    };
    
    const scrollHandler = (elmRef, selected) => {
        sessionStorage.setItem("selectedCVLinkFromHome",selected);
        
        const elementsTopOffset = elmRef.current ? elmRef.current.offsetTop : 0;
        window.scrollTo({
            top:elementsTopOffset,
            behavior:"smooth"
        })
    }
    const [opacityCVAndAboutDivs, setOpacityCVAndAboutDivs] = useState("1");
    const [opacityTitleBox, setOpacityTitleBox] = useState("1");
    const [opacityProjectsAndContactDivs, setOpacityProjectsAndContactDivs] = useState("1");
    const [cvOpacity, setCvOpacity] = useState("0");
    const [projectsOpacity, setProjectsOpacity] = useState("0");
    const [contactOpacity, setContactOpacity] = useState("0");

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            const screenHeight = window.screen.height;

            //Section 1 - Home screen
            if (currentScrollY >= refs.section1.current.offsetTop && currentScrollY < refs.section2.current.offsetTop) {
                //gives new current scroll value as an opacity value to the sideImgCV - image on the right side in the CV page
                const curntOp = 1 - currentScrollY / screenHeight;
                const imgOpcty = 1 - curntOp;
                setCvOpacity(imgOpcty);

                if(currentScrollY < screenHeight/2){
                    const newOpacity = 1 - currentScrollY / (screenHeight / 2);
                    setOpacityCVAndAboutDivs(newOpacity);
                    setOpacityTitleBox(newOpacity);
                }
                if(currentScrollY >= screenHeight/2){
                    const newOpacity = 1 - ((currentScrollY / (screenHeight / 2)) - 1);
                    setOpacityProjectsAndContactDivs(newOpacity);
                }
            }

            //Section 2 - CV screen
            if (currentScrollY >= refs.section2.current.offsetTop && currentScrollY < refs.section3.current.offsetTop) {
                //gives new current scroll value as an opacity value to the sideImgCV - image on the right side in the CV page
            
                // let currentHeightIndex = currentScrollY/screenHeight;
                // const toZero = 1 - (currentHeightIndex - 1);
                // setCvOpacity(toZero);

                // const toMax = currentHeightIndex-1;
                // setProjectsOpacity(toMax);

                const nextSectionsOffset = refs.section3.current.offsetTop;
                const scrollHeightLeftToNextSection = nextSectionsOffset - currentScrollY;
                const toNextSectionIndex = scrollHeightLeftToNextSection/currentScrollY;

                const toZero = toNextSectionIndex;
                setCvOpacity(toZero);

                const toMax = 1-toNextSectionIndex;
                setProjectsOpacity(toMax);
            }

            //Section 3 - Projects screen 
            if (currentScrollY >= refs.section3.current.offsetTop && currentScrollY < refs.section4.current.offsetTop) {
                //gives new current scroll value as an opacity value to the sideImgCV - image on the right side in the CV page
                const nextSectionsOffset = refs.section4.current.offsetTop;
                const scrollHeightLeftToNextSection = nextSectionsOffset - currentScrollY;
                const toNextSectionIndex = scrollHeightLeftToNextSection/currentScrollY;

                const toZero = toNextSectionIndex*2;
                setProjectsOpacity(toZero);

                const toMax = 1-toZero;
                setContactOpacity(toMax);   
            }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      const getFooterContent = () => {
        let currentYear = new Date().getFullYear();
        return `@Copyrights ${currentYear} - All Rights Reserved by Mehmet Kaan Taspunar`;
      }

    return ( 
        <div className="container">
            <Navbar refs={refs} />
            <div className='backgroundIMG'/>
            <div className='section' ref={refs.section1}>
                <div className='home'>
                    <nav className="welcomeBarMobile" 
                        style={
                        {
                            opacity: opacityTitleBox
                        }} 
                        >
                        <div className="welcomeBarTitleBox">
                            <h1 className='welcomeBarTitle'>Portfolio of Mehmet Kaan Taspunar</h1>
                        </div>
                    </nav>
                    <div className='homeLink cv' 
                        style={
                            {
                                opacity: opacityCVAndAboutDivs
                            }} 
                        onClick={() => scrollHandler(refs.section2, "CV")}>
                        <div className='foreDiv foreDivCV'></div>
                        <p className='cvP'>CV</p>
                    </div>
                    <div className='homeLink about'
                         style={
                            {
                                opacity: opacityCVAndAboutDivs
                            }} 
                        onClick={() => scrollHandler(refs.section2, "Skills")}>
                        <div className='foreDiv foreDivAbout'></div>
                        <p className='aboutP'>Skills</p>
                    </div>
                    <nav className="welcomeBar" 
                        style={
                        {
                            opacity: opacityTitleBox
                        }} 
                        >
                        <div className="welcomeBarTitleBox">
                            <h1 className='welcomeBarTitle'>Portfolio of Mehmet Kaan Taspunar</h1>
                        </div>
                    </nav>
                    <div className='homeLink projects' 
                        style={
                            {
                                opacity: opacityProjectsAndContactDivs
                            }} 
                        onClick={() => scrollHandler(refs.section3, sessionStorage.getItem("selectedCVLinkFromHome"))}>
                        <div className='foreDiv foreDivProjects'></div>
                        <p className='projectsP'>Projects</p>
                    </div>
                    <div className='homeLink contact'
                        style={
                            {
                                opacity: opacityProjectsAndContactDivs
                            }}  
                        onClick={() => scrollHandler(refs.section4, sessionStorage.getItem("selectedCVLinkFromHome"))}>
                        <div className='foreDiv foreDivContact'></div>
                        <p className='contactP'>Contact</p>
                    </div>
                </div>
            </div>
            <div className='section' ref={refs.section2}>
                <CV contentOpacity={cvOpacity}/>
            </div>
            <div className='section' ref={refs.section3}>
                <Projects contentOpacity={projectsOpacity}/>
            </div>
            <div className='section' ref={refs.section4}>
                <Contact contentOpacity={contactOpacity}/>
            </div>
            <div className='footer'>
                {getFooterContent()}
            </div>
        </div>
    );
}
export default Home;