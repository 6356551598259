import '../stylesheets/navbar.css';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHouse, faListCheck, faFileContract, faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';

const Navbar = ({ refs }) => {
    //Icons
    const homeIcon = <FontAwesomeIcon icon={faHouse} />;
    const cvIcon = <FontAwesomeIcon icon={faFileContract} />;
    const projectsIcon = <FontAwesomeIcon icon={faListCheck} />;
    const contactIcon = <FontAwesomeIcon icon={faEnvelope} />;
    const upIcon = <FontAwesomeIcon icon={faArrowAltCircleUp} />;

    const { section1, section2, section3, section4, } = refs;

    const [selected, setSelected] = useState('');
    const [show, setShow] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          const currentScrollY = window.scrollY;
          const offsetTop1 = section1.current.offsetTop;
          const offsetTop2 = section2.current.offsetTop;
          const offsetTop3 = section3.current.offsetTop;
          const offsetTop4 = section4.current.offsetTop;
    
          if (currentScrollY >= offsetTop1 && currentScrollY < offsetTop2) {
            setSelected("home");
            setShow(false);
          } else if (currentScrollY >= offsetTop2 && currentScrollY < offsetTop3) {
            setShow(true);
            setSelected("cv");
          } else if (currentScrollY >= offsetTop3 && currentScrollY < offsetTop4) {
            setShow(true);
            setSelected("projects");
          } else if (currentScrollY >= offsetTop4) {
            setShow(true);
            setSelected("contact");
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    const scrollHandler = (elmRef, selectedL) => { 
      // selectedL === "cv" ? sessionStorage.setItem("selectedCVLinkFromHome", "CV") : sessionStorage.setItem("selectedCVLinkFromHome", sessionStorage.getItem("selectedCVLinkFromHome"));
        const elementsTopOffset = elmRef.current ? elmRef.current.offsetTop : 0;
        window.scrollTo({
            top:elementsTopOffset,
            behavior:"smooth"
        })
    }
  
    return (
      <>
      {show && (
        <>
          <nav id='navbar' className="navbar">
            <div className="titleBox">
                <img className='icon' src={require('../assets/images/home/icon.png')} onClick={() => scrollHandler(section1, "home")} alt="logoMKT" />
            </div>
            <ul className="links">
                <li className={selected === "home" ? "selected link" : "link"} onClick={() => scrollHandler(section1, "home")}>{homeIcon}</li>
                <li className={selected === "cv" ? "selected link biggerSVG" : "link biggerSVG"} onClick={() => scrollHandler(section2, "cv")}>{cvIcon}</li>
                {/* <li className={selected === "skills" ? "selected link biggerSVG" : "link biggerSVG"} onClick={() => scrollHandler(section2, "skills")}>{aboutIcon}</li> */}
                <li className={selected === "projects" ? "selected link" : "link"} onClick={() => scrollHandler(section3, "projects")}>{projectsIcon}</li>
                <li className={selected === "contact" ? "selected link" : "link"} onClick={() => scrollHandler(section4, "contact")}>{contactIcon}</li>
            </ul>
          </nav>
          <button className='upBtn' onClick={() => scrollHandler(section1, "home")}>{upIcon}</button>
        </>
        )} 
      </>
    );
}

export default Navbar;