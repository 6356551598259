import '../stylesheets/contact.css';
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = ({contentOpacity}) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [selectedLogo, setSelectedLogo] = useState("informationLogo");
    const [responseMessage, setResponseMessage] = useState(false);

    const form = useRef();
    const handleSubmit = (e) => {
        e.preventDefault();

        const contactForm = document.querySelector(".contactForm");
        // Here, you can add code to send the form data to your server or handle it as needed.
        // You might use AJAX to send the data to a server-side script or an API.
        
        emailjs.sendForm('service_xgsvp25', 'template_g8l1uec', form.current, 'J2EJOFCIP1KG1NrPk')
        .then((result) => {
            // console.log(result.text);

              // For this example, we'll simply display a message on the page.
            setResponseMessage(true);

            setTimeout(() => {
                setResponseMessage(false);
            }, 2000);
            // You can also reset the form after submission.
            contactForm.reset();

        }, (error) => {
            console.log(error.text);
        });
    }

    const handleInfoLogo = (logo) => {
        setSelectedLogo(logo);
    }

    return ( 
        <div className="content">
            <div className='contactDiv'  style={{
                    opacity:contentOpacity
                }}>
                    <h2 className='contactTitle'>Contact</h2>
                <div className='mail_info'>
                    {selectedLogo === "informationLogo" ? 
                        <div className='info'>
                            <h1 className='titleInfo'>Get in <span>Touch!</span></h1>
                            <p>Thank you for visiting my portfolio. I'm excited to connect with you and discuss potential collaborations, projects, or any inquiries you may have. Feel free to reach out to me through any of the following channels: <br/><br/></p>
                            <h3>Call me - <span><a href="tel:0046723171061">0723171061</a></span><br/><br/></h3>
                            <h3>Social Media</h3>
                            <p>Connect with me on various social media platforms. You can find me on LinkedIn, Instagram, Facebook and GitHub. I'm always open to new connections and discussions. <br/><br/></p>
                            <div className='contactLogosSmaller'>
                                <a target='_blank' href="https://www.linkedin.com/in/mehmet-kaan-taspunar/"><div className="linkedIn contactLogo"></div></a>
                                <a target='_blank' href="https://www.instagram.com/kaan_tspnr/"><div className="instagram contactLogo"></div></a>
                                <a target='_blank' href="https://www.facebook.com/kaan.tspnr/"><div className="facebook contactLogo"></div></a>
                                <a target='_blank' href="https://github.com/Mehmet-Kaan"><div className="github contactLogo"></div></a>
                            </div>
                            <h3>Email - <span>taspunar10@hotmail.com</span></h3>
                            <p>You can drop me an email. I check my inbox regularly and will get back to you as soon as possible.</p>
                            <p>If you prefer, you can also use the contact form on the bottom to send me a message directly from this page. Just fill in your details, your message, and click 'Send Message.' I'll receive your message instantly. <br/><br/></p>
                            <p>I value your feedback, questions, and ideas, so don't hesitate to get in touch. I look forward to hearing from you and exploring how we can work together to achieve your goals.</p>
                        </div>
                    :
                        <form className='contactForm' ref={form} onSubmit={handleSubmit}>
                        <h3 style={{
                            textAlign:"center",
                            marginBottom:"5px"
                        }}>Write me a message</h3>
                        <input 
                            type="text" 
                            id="name"
                            name='name'
                            autoComplete="off"
                            placeholder='Your Name'
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input 
                            type="text" 
                            id="email"
                            name='email'
                            autoComplete="off"
                            placeholder='Your Email'
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <input 
                            type="text" 
                            id="subject"
                            autoComplete="off"
                            name='subject'
                            placeholder='The Subject'
                            onChange={(e) => setSubject(e.target.value)}
                        />
                        <textarea 
                            type="text" 
                            id="message"
                            name='message'
                            placeholder='Your Message'
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />            
                        <button className='sendBtn' disabled= {!message && !email ? true : false}>Send Message</button>
                        {responseMessage &&  
                            <p className='responseMessage'>Thank you! Your message has been sent.</p>
                        }
                        </form>
                    }
                </div>
                <div className='infoLogos'>
                    <img className={selectedLogo === "informationLogo" ? `infoLogo selected` : "infoLogo"} onClick={() => handleInfoLogo("informationLogo")} src={require("../assets/logos/information.jpg")} alt='informationLogo'/>
                    <img className={selectedLogo === "emailLogo" ? `infoLogo selected` : "infoLogo"} onClick={() => handleInfoLogo("emailLogo")} src={require("../assets/logos/email1.jpg")} alt='emailLogo'/>
                </div>
                <div className='contactLogos'>
                    <a target='_blank' href="https://www.linkedin.com/in/mehmet-kaan-taspunar/"><div className="linkedIn contactLogo"></div></a>
                    <a target='_blank' href="https://www.instagram.com/kaan_tspnr/"><div className="instagram contactLogo"></div></a>
                    <a target='_blank' href="https://www.facebook.com/kaan.tspnr/"><div className="facebook contactLogo"></div></a>
                    <a target='_blank' href="https://github.com/Mehmet-Kaan"><div className="github contactLogo"></div></a>
                </div>
            </div>
        </div>
    );
}
 
export default Contact;