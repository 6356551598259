import '../stylesheets/intro.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RainStream from './RainStream';

const Intro = () => {
    const [streamCount, setStreamCount] = useState(Math.floor(window.innerWidth / 26));
    const navigate = useNavigate();

   // Add a handler to stop the interval
    useEffect(()=>{

        setTimeout(() => {
            document.querySelector(".welcomeDiv").classList.remove("nullOpacity");
            setTimeout(() => {
                document.querySelector(".welcomeDiv").classList.add("nullOpacity");
            }, 4000);
        }, 2000);

        setTimeout(() => {
            if(document.querySelector(".stream")){
                let allStreams = document.querySelectorAll(".stream");
                allStreams.forEach(element => {
                   element.classList.add("nullOpacity"); 
                });
            }

            document.getElementById("intro").classList.add("nullOpacity");

            setTimeout(() => {
                setStreamCount(0);
                sessionStorage.setItem("currentPage","home");
                navigate('/home');
            }, 1000);
        }, 6000);

        // setTimeout(() => {
        //  setStreamCount(0);
        //  document.getElementById("background").classList.add("hide");
        //  document.querySelector(".navbar").classList.remove("nullOpacity");
        // }, 10000);
    },[])

    return (
        <div className='contentIntro'>
        <div id="intro">
            {new Array(streamCount).fill().map((_, index) => (
                <RainStream key={index}/>
            ))}
        </div>
        <div className="welcomeDiv nullOpacity">
            <img id='logo' src={require('../assets/images/home/icon.png')}alt="logoMKT" />
            <p>Mehmet Kaan Taspunar</p>
            <div className='logoBackground'></div>
            <div className='logoBackgroundWide'></div>
        </div>
        </div>
    );
}
 
export default Intro;